import React, { useEffect, useState } from 'react'
import type { ReactNode } from 'react'

// import {
//   KeyboardKey,
//   KeyboardModifierKey,
//   useKeyDown,
// } from '~/hooks/useKeyDown'

export const SearchPaletteContext = React.createContext({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => {},
})

export const SearchPaletteProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      // Client-side only code
      const handleKeyDown = (e: KeyboardEvent) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
          e.preventDefault()
          setIsOpen((prev) => !prev)
        }
      }

      window.addEventListener('keydown', handleKeyDown)
      return () => window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isMounted])

  return (
    <SearchPaletteContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </SearchPaletteContext.Provider>
  )
}
